import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Toast } from "reactstrap"
import { FormattedMessage } from "gatsby-plugin-intl"

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 40px;
  margin-right: 0px;
  padding-top: 1rem;
  padding-left: 0px;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: all;
 

  @media (max-width: 768px) {
    max-width: 350px;
  }
`

const StyledContactItem = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledTitle = styled.h2`
  margin-bottom: 2px;
`

const StyledContent = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.xxxxs};
  white-space: pre-line;
`
const ContactItem = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 15px;
`;
const ContactText = styled.p`
  margin: 10px 0px 0px;
  font-size: 16px;
  white-space: pre-line;
`;


const ContactInformation = ({ contactInfo }) => (
  <StyledWrapper>
    
    {contactInfo.map(({ title, content, mail1, mail2 }) => (

          <ContactItem className="footer__item" key={title}>
          
          {title!=='' ? <StyledTitle>{title}</StyledTitle> : null }
            <ContactText>
            {content}
            
            </ContactText>
            {mail1!=='' ? <ContactText>{mail1}</ContactText> : null }
            {mail2!=='' ? <ContactText>{mail2}</ContactText> : null }
            
          </ContactItem>


      // <StyledContactItem key={title}>
      //   <StyledTitle>{title}</StyledTitle>
      //   <StyledContent>{content}</StyledContent>
      // </StyledContactItem>
    ))}
  </StyledWrapper>
)

ContactInformation.propTypes = {
  contactInfo: PropTypes.array.isRequired,
}

export default ContactInformation
