import styled from "styled-components";
import ArrowDown from "images/arrow-down.svg";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
 
  max-width:500px;

  @media (max-width: 425px) {
    max-width: 350px;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  background-color: white;
  color: black;
  font-size: 15px;
  padding: 0.5rem;
  outline-style: none;
  margin-block: 15px;
  margin-right: 0px;

  cursor: pointer;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: black;
  border-right-color: black;
  border-bottom-color: black;
  border-left-color: black;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  appearance: none;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: all;

  &:hover,
  &:focus {
    border: ${({ theme }) =>
      ` 1px solid ${theme.colors.contact.formFieldBorderActive}`};
  }

  ::placeholder {
    color: black;
  }
`;

export const Textarea = styled.textarea`
width: 100%;
height: 140px;
background-color: white;
color: black;
font-size: 15px;
padding: 0.5rem;
outline-style: none;
resize: none;
margin-block: 15px;
margin-right: 0px;
cursor: pointer;
border-top-width: 1px;
border-right-width: 1px;
border-bottom-width: 1px;
border-left-width: 1px;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
border-top-color: black;
border-right-color: black;
border-bottom-color: black;
border-left-color: black;
transition-duration: 0.25s;
transition-timing-function: ease;
transition-delay: 0s;
transition-property: all;
border-top-left-radius: 0px;
border-top-right-radius: 0px;
border-bottom-right-radius: 0px;
border-bottom-left-radius: 0px;
appearance: none;


  &:hover,
  &:focus {
    border-top-width: 1px;
border-right-width: 1px;
border-bottom-width: 1px;
border-left-width: 1px;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
border-top-color: green;
border-right-color: green;
border-bottom-color: green;
border-left-color: green;
  }
  ::placeholder {
    color: black;
  }
`;
export const FormButton = styled.button`
margin-block: 15px;
margin-right: 0px;
background-color: rgb(0, 79, 158);
color: white;
font-size: 15px;
border-top-left-radius: 2px;
border-top-right-radius: 2px;
border-bottom-right-radius: 2px;
border-bottom-left-radius: 2px;
border-top-style: none;
border-right-style: none;
border-bottom-style: none;
border-left-style: none;
width: 110px;
height: 30px;
padding-top: 0.2rem;
outline-style: none;
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
cursor: pointer;
font-weight: bold;
transition-duration: 0.25s;
transition-timing-function: ease;
transition-delay: 0s;
transition-property: all;

  &:hover {
    background-color: rgb(0, 79, 158);
    color: white;
  }
`;
export const OptionSelect = styled.select`
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.contact.formFieldBackground};
  color: ${({ theme, selectedVal }) =>
    selectedVal
      ? theme.colors.contact.formFieldBorderActive
      : theme.colors.contact.formFieldText};
  font-size: ${({ theme }) => theme.fontSize.xxxxs};
  padding: 0.5rem;
  outline: none;
  appearance: none;
  cursor: pointer;
  border-radius: 0px;
  border: ${({ theme }) =>
    ` 1px solid ${theme.colors.contact.formFieldBorder}`};
  transition: all 0.25s ease;

  &:hover,
  &:focus {
    border: ${({ theme }) =>
      ` 1px solid ${theme.colors.contact.formFieldBorderActive}`};
  }

  option {
    color: ${({ theme }) => theme.colors.contact.formFieldBorderActive};
  }
`;

export const OptionContainer = styled.div`
  width: 100%;
  position: relative;
  max-height: 40px;
  margin: 15px 0;
`;

export const Icon = styled.label`
  position: absolute;
  width: 12px;
  height: 7px;
  right: 10px;
  top: calc(50% - 3.5px);
  background-image: url(${ArrowDown});
  cursor: pointer;
  transform: scale(0.5);
`;

export const AddProduct = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.contact.addButtonBackground};
  color: ${({ theme }) => theme.colors.contact.addButtonText};
  font-size: ${({ theme }) => theme.fontSize.xxxxs};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  transition: all 0.25s ease;
  cursor: pointer;
  text-transform: uppercase;
  border: none;

  &:hover {
    background-color: black;
    color: white;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.contact.formFieldText};
  font-size: ${({ theme }) => theme.fontSize.xxxxs};
  margin: 0;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
