import { colors } from "./colors"

export const theme = {
  colors,
  fontSize: {
    xxxxxs: "0.5rem",
    xxxxs: "0.65rem",
    xxxs: "0.8rem",
    xxs: "1rem",
    xs: "1.2rem",
    s: "1.5rem",
    m: "2rem",
    l: "2rem",
    xl: "3rem",
    xxl: "3.5rem",
    xxxl: "3.8rem",
    xxxxl: "4rem",
    xxxxxl: "5rem",
  },
  fontWeights: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },
}
