import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ lang }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
          }
        }
      }
    `
  )

  return (
    <Helmet htmlAttributes={{ lang }}>
      <title>{site.siteMetadata.title}</title>
      <meta name="author" content={site.siteMetadata.author} />
      <meta name="description" content={site.siteMetadata.description} />
      <meta name="keywords" content={site.siteMetadata.keywords} />
    </Helmet>
  )
}

SEO.propTypes = {
  lang: PropTypes.string,
}

SEO.defaultProps = {
  lang: `en`,
}

export default SEO
