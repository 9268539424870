import styled, { css } from "styled-components"

import ArrowDown from "images/arrow-down.svg"

const Arrow = styled.div`
  width: 12px;
  height: 7px;
  right: 10px;
  background-image: url(${ArrowDown});
  cursor: pointer;
  transform: ${({ show }) =>
    show ? "scale(0.5) rotate(180deg)" : "scale(0.5)"};

  ${({ left }) =>
    left &&
    css`
      transform: rotate(90deg) scale(1.2);
    `}

  ${({ right }) =>
    right &&
    css`
      transform: rotate(-90deg) scale(1.2);
    `}
`

export default Arrow
