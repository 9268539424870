import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    text-decoration: none;
    font-family: 'Roboto';
}

body {
    font-family: 'Roboto';
    max-width: 100vw;
    width: 100%;
}

body, html {
    overflow-x: hidden;
}
`

export default GlobalStyle
