import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"

import ArrowDown from "images/arrow-down.svg"

const StyledSelect = styled.select`
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.contact.formFieldBackground};
  color: ${({ theme, selectedVal }) =>
    selectedVal
      ? theme.colors.contact.formFieldBorderActive
      : theme.colors.contact.formFieldText};
  font-size: ${({ theme }) => theme.fontSize.xxxxs};
  padding: 0.5rem;
  outline: none;
  appearance: none;
  cursor: pointer;
  border-radius: 0px;
  border: ${({ theme }) =>
    ` 1px solid ${theme.colors.contact.formFieldBorder}`};

  transition: all 0.25s ease;

  &:hover,
  &:active {
    border: ${({ theme }) =>
      ` 1px solid ${theme.colors.contact.formFieldBorderActive}`};
  }
  option {
    color: ${({ theme }) => theme.colors.contact.formFieldText};
  }
`

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  max-height: 40px;
  margin: 15px 0;
  max-width:280px;
`

const StyledIcon = styled.label`
  position: absolute;
  width: 12px;
  height: 7px;
  right: 10px;
  top: calc(50% - 3.5px);
  background-image: url(${ArrowDown});
  cursor: pointer;
  transform: scale(0.5);
`

const InputSelect = ({ productIndex, selectedVal, ...props }) => {
  const intl = useIntl()
  return (
    <StaticQuery
      query={graphql`
        {
          product {
            products {
              productName
            }
          }
        }
      `}
      render={({ product: { products } }) => (
        <StyledContainer>
          <StyledSelect
            name={`product-${productIndex}`}
            defaultValue="DEFAULT"
            selectedVal={selectedVal}
            {...props}
          >
            <option value="DEFAULT" disabled>
              { productIndex !=='' ? productIndex : intl.formatMessage({ id: "contact.formProductChoose" })  }
            </option>
            {products.map(({ productName }) => (
              <option value={productName} key={productName}>
                {productName}
              </option>
            ))}
          </StyledSelect>
          <StyledIcon htmlFor={`product-${productIndex}`} />
        </StyledContainer>
      )}
    />
  )
}

InputSelect.propTypes = {
  productIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  selectedVal: PropTypes.bool.isRequired,
}

export default InputSelect
