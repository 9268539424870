import { FormattedMessage, Link } from "gatsby-plugin-intl"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { globalHistory } from "@reach/router"
import { headerContent } from "utils/intlHelper"

import useWindowSize from "utils/useWindowSize"

import MenuSwitcher from "../MenuSwitcher/MenuSwitcher"
import MobileMenu from "../MobileMenu/MobileMenu"
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher"
import { parsePath } from 'gatsby-link';
 
import  HeaderLogo  from '../../../static/logo.svg';
import KredobankLogo from "../../../static/kredobank.svg"
import { render } from "react-dom"




const StyledContainer = styled.header`
  width: 90vw;
  max-width:1680px;
  height: 100px;
  padding-top: 0px;
  display: flex;
  margin-inline:auto;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  z-index: 100;
  // @media (max-width: 700px) {
  //   margin-top: 70px;
  // }
`

const StyledLogo = styled.h2`
  font-size: 12px;
  color: ${({ ishome, theme }) =>
    ishome ? theme.colors.logoYellow : theme.colors.logoBlack};
  margin: 0;
  cursor: pointer;
`

const StyledNavigation = styled.nav`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  min-width: 30vw;
  max-width: 250px;

  @media (min-width: 1680px) {
    min-width: 20vw;
  }

  @media (max-width: 1100px) {
    margin-left: 50px;
    gap:10px;
  }
  
  @media (max-width: 700px) {
    gap:8px;
  }
`

const StyledLink = styled(Link)`
font-size: 16px;
color: black;
font-weight: bold;
text-transform:capitalize;

@media (max-width: 1024px){
  font-size: 13px;
}
@media (max-width: 768px){
  font-size: 10px;
}

`

const LogoContainer = styled.div`
display: flex;
align-items: center;
margin-top:25px;
gap:20px;
`
const NavigationContainer = styled.div`
display:flex;
`

const LinkBtn = styled.a`
cursor:pointer;
  width: 170px;
  height: 40px;
  padding: 7px 42px;
  background: rgb(255, 122, 0);
  border-radius: 2px;
  font-size: 16px;
  text-align: center;
  color: white;
`

const Header = () => {
  const size = useWindowSize()

  const [open, setOpen] = useState(false)
  const [isHome, setIsHome] = useState(true)
  const [howPage, setHowPage] = useState(true)

  const path = globalHistory.location.pathname



  useEffect(() => {
    if (
      path === "/en/" ||
      path === "/fr/" ||
      path === "/" ||
      path === "/pl/" ||
      path === "/uk/"
    ) {
      setIsHome(true)
    } else {
      setIsHome(false)
    }

    path.includes('/services/fertilizers')?setHowPage(true): setHowPage(false)
    



  }, [path])

  return (
    <StyledContainer>
      <>

        <LogoContainer>
          <Link to="/">
            <img src={HeaderLogo} />
          </Link>
          <a href="https://kredobank.com.ua/business/malomu-ta-serednomu-biznesu/agrobiznes/kredytna-liniya-agro" target="_blank">
          <img src={KredobankLogo} />
          </a>
          </LogoContainer> 
       
        {size.width >= 730 ? (
          <>
            <NavigationContainer>
            <StyledNavigation>
              {headerContent.menu.map(({ name, pathname }) => {

                let transletaMenu = <FormattedMessage id={"menu."+name} />
                let pathPage = name =='services' && howPage ? '/' : pathname;

                return(
                
                <StyledLink
                  key={name}
                  to={pathPage}
                  activeStyle={{
                    fontWeight: 700,
                  }}
                >
                  {transletaMenu}
                </StyledLink>
              )})}
               <LanguageSwitcher   />
            </StyledNavigation>
            {size.width >= 800 ? (
             <LinkBtn href="#contact"  ><FormattedMessage id="home.firstView.button"/></LinkBtn>) : null}

            </NavigationContainer>
            
           
          </>
        ) : (
          <MenuSwitcher
            light={isHome}
            open={open}
            onClick={() => setOpen(!open)}
          />
        )}
        {size.width <= 730 ? (
          <MobileMenu open={open} setOpen={setOpen} />
        ) : null}
      </>
    </StyledContainer>
  )
}

export default Header
