
export const headerContent = {
  menu: [
    {
      name: "home",
      pathname: "/#about",
    },
    {
      name: "services",
      pathname: '/#fertilizers',
    },
   
    {
      name: "contact",
      pathname: "/#contact",
    },
  ],
  logoPart1: "GRAIN",
  logoPart2: "POWER"

}

export const footerContent = {
  logo: "GRAIN POWER",
  menu: [
    {
      name: "Home",
      pathname: "/",
    },
    {
      name: "Services",
      pathname: "/services/fertilizers",
    },
    {
      name: "Contact",
      pathname: "/contact/",
    },
  ],
  text: "International and large-scale export & import company",
}
