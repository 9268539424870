import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import GlobalStyle from "utils/GlobalStyle"

import Header from "components/Header/Header"
import Footer from "components/Footer/Footer"

import SEO from "components/SEO"

import "./layout.css"
import { theme } from "utils/theme"

const Layout = ({ children }) => {
  const intl = useIntl()

  return (
    <>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <SEO lang={intl.locale} />
          <Header />
          <main>{children}</main>
          <Footer />
        </>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
