import React, { useState } from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { useFormik } from "formik"
import { navigate } from "@reach/router"
import axios from "axios"
import PropTypes from "prop-types"

import { validate } from "./validate"

import InputSelect from "components/InputSelect/InputSelect"
import {
  FormContainer,
  Input,
  Textarea,
  FormButton,
  OptionSelect,
  OptionContainer,
  Icon,
  AddProduct,
  ErrorMessage,
} from "./Form.style"

const FormFooter = ({ setShowModal }) => {
  const intl = useIntl()


  const sendEmail = () => {
    const { name, email,  message } = formik.values
    setShowModal(true)
    setTimeout(() => {
      setShowModal(false)
    }, 1500)
   

    var data = JSON.stringify({
      name: `${formik.values.name}`,
      senderEmail: `${formik.values.email}`,
      message: `${formik.values.message}`,
    })

    var config = {
      method: "post",
      url:
        "https://9upytbldjj.execute-api.eu-central-1.amazonaws.com/test/send",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: data,
    }
    axios(config)
      .then(function (values) {
        const { data } = values
        const bodyData = JSON.parse(data.body)

        if (bodyData.content === "success") {
          setShowModal(true)
          setTimeout(() => {
            // navigate("/")
            setShowModal(false)
          }, 1500)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validate,
    onSubmit: () => {
      sendEmail()
    },
    validateOnChange: false,
    validateOnBlur: false,
  })



  // intl inputs' labels

  const addMoreProductsLabel = intl.formatMessage({
    id: "contact.addMoreProductsLabel",
  })

  // const fertilizersLabel = intl.formatMessage({
  //   id: "services-options.options.0.name",
  // })

  // const linesLabel = intl.formatMessage({
  //   id: "services-options.options.1.name",
  // })

  // const selectCategoryLabel = intl.formatMessage({
  //   id: "services-options.title",
  // })

  return (
    <>
      <FormContainer onSubmit={formik.handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder={intl.formatMessage({ id: "contact.formNameField" })}
          onChange={formik.handleChange}
          value={formik.values.name}
          required
          minLength="3"
        />
        {formik.errors.name ? (
          <ErrorMessage>{formik.errors.name}</ErrorMessage>
        ) : null}
        <Input
          type="email"
          name="email"
          placeholder={intl.formatMessage({ id: "contact.formEmailField" })}
          onChange={formik.handleChange}
          value={formik.values.email}
          required
        />
        {formik.errors.email ? (
          <ErrorMessage>{formik.errors.email}</ErrorMessage>
        ) : null}
       
        <Textarea
          type="text"
          name="message"
          placeholder={intl.formatMessage({ id: "contact.formMessageField" })}
          onChange={formik.handleChange}
          value={formik.values.message}
        />
        <FormButton type="submit" onClick={formik.handleSubmit}>
          <FormattedMessage id="contact.formButton" />
        </FormButton>
      </FormContainer>
    </>
  )
}

FormFooter.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
}

export default FormFooter
