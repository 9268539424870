import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import gsap, { Power3 } from "gsap"
import PropTypes from "prop-types"
import noScroll from "no-scroll"

import { headerContent } from "utils/intlHelper"
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher"

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.menuMobile.menuBackground};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 200;
  transform: translateX(-100vw);
`

const StyledLinkContainer = styled.nav`
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
`
const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.m};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.menuMobile.menuText};
  margin-bottom: 50px;
  cursor: pointer;

  @media (max-width: 600px) and (orientation: landscape) {
    margin-bottom: 25px;
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`

const StyledLanguageContainer = styled.div`
  display: flex;
`

const MobileMenu = ({ open, setOpen }) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline()
    tl.set(wrapperRef.current, {
      display: "none",
    })
    if (open === true) {
      noScroll.on()
      tl.fromTo(
        wrapperRef.current,
        { transform: "translateX(-100vw)", display: "flex" },
        {
          transform: "translateX(0)",
          ease: Power3,
          duration: 0.4,
          delay: 0.3,
        }
      )
    } else if (open === false) {
      tl.set(wrapperRef.current, {
        transform: "translateX(-100vw)",
        display: "none",
      })
      noScroll.off()
    }
  }, [open])

  return (
    <>
      <StyledWrapper ref={wrapperRef}>
        <StyledLinkContainer>
          {headerContent.menu.map(({ name, pathname }) => (
            <StyledLink key={name} to={pathname} onClick={() => setOpen(false)}>
              {name}
            </StyledLink>
          ))}
        </StyledLinkContainer>
        <StyledLanguageContainer>
          <LanguageSwitcher black />
        </StyledLanguageContainer>
      </StyledWrapper>
    </>
  )
}

MobileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default MobileMenu
