import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { footerContent } from "utils/intlHelper";

import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import noScroll from "no-scroll"
import ContactInformation from "components/ContactInformation/ContactInformation"

import footerImage from '../../images/footer.png';

import useWindowSize from "utils/useWindowSize";
import FormFooter from "../FormFooter/FormFooter";

import LogoFooter from '../../../static/logo-footer.svg'
import FormModal from "../FormModal/FormModal";


const StyledWrapper = styled.div`
    width: 100%;
    max-height: 100%;
    background-image: url(${footerImage});
    margin-top: 25px;
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1680px;
    margin-inline: auto;

    @media (max-width:768px){
      flex-direction:column-reverse;
    }


`;

const FooterBlock = styled.footer`
  width: 100vw;
  height: 100px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  background-color: black;
  z-index: 100;
  max-width: 1680px;
    margin-inline: auto;

`

const StyledTextBase = styled.p`
  color: ${({ theme }) => theme.colors.footerText};
  font-family: "Roboto";
  font-size: ${({ theme }) => theme.fontSize.xxxxs};
  margin: 0;
`;

const StyledTitle = styled(StyledTextBase)`
  margin: 0;
`;

const StyledNavigation = styled.nav``;
const StyledText = styled(StyledTextBase)`
  font-size: ${({ theme }) => theme.fontSize.xxxxs};
`;
const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.footerText};
  font-family: "Roboto";
  font-size: ${({ theme }) => theme.fontSize.xxxxs};
  margin-right: 10px;
  text-transform: capitalize;
  transition: color 0.25s ease;
  &:hover {
    color: black;
  }
`;

const MobileFooter = styled(StyledWrapper)`
  height: 35px;
  justify-content: center;
  h2 {
    font-size: ${({ theme }) => theme.fontSize.xxxxxs};
    font-family: "Roboto";
    color: ${({ theme }) => theme.colors.footerText};
    text-transform: capitalize;
    margin: 0;
  }
`;
const ContactItem = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 15px;
`;
const ContactTitle = styled.p`
  font-size: 36px;
  margin-bottom: 0;
  font-weight: bold;
  text-transform:capitalize;
  color:white;

  @media (max-width:780px){
  font-size:25px;
}

  @media(max-width:700px){  
    font-size:30px;

  }

  @media(max-width:500px){  
    font-size:20px;

  }
  
`;

const FormContainer = styled.div`
padding-top: 110px;
// padding-top: 30px;
padding-left: 70px;

@media(max-width:1024px){
 padding-left: 20px;
}

@media(max-width:768px){
  padding-left:0;
}

`


const ContactContainer = styled.div`

max-width:  60%;
width:100%;
height:  max-content;
min-height: 80vh;
display:  flex;
flex-direction:  column;
background:  rgba(0, 184, 18, 0.84);
padding-inline: 4em;

@media (max-width:1024px){
 padding-inline: 2em;
}

@media (max-width:768px){
  max-width:100%;
  width:100%;
  align-items: center;
}
@media(max-width:500px){  
  padding-inline: 1em;


}
`
const Contact = styled.div`
          color: white;
          font-size: 36px;
          padding-top: 110px;
          margin-left: 66px;
          max-width: 30%;
          width:100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items:center;

        @media (max-width: 768px){
          margin-inline:auto;
          padding-inline:10px;
          
        }
`

const LogoFooterTag = styled.img`
  margin:0
`

const Footer = () => {
  const size = useWindowSize();
  const intl = useIntl();

  const contactInfo = [
    {
      title: intl.formatMessage({ id: "contact.contactInfo.0.title" }),
      content: intl.formatMessage({ id: "contact.contactInfo.0.content" }),
    },
    {
      title: '',
      content: intl.formatMessage({ id: "contact.contactInfo.1.content" }),
      
    },
    {
      title: '',
      content: intl.formatMessage({ id: "contact.contactInfo.2.content" }),
      mail1:intl.formatMessage({ id: "contact.contactInfo.2.mail1" }),
      mail2:intl.formatMessage({ id: "contact.contactInfo.2.mail2" })
    },
  ]

  const [showModal, setShowModal] = useState(false);
  
  useEffect(() => {
    if (showModal) {
      noScroll.on()
    } else {
      noScroll.off()
    }
  }, [showModal])


  return (
    <>
      <StyledWrapper>
        <Contact  >
          <div className="footer__contact">
          <ContactTitle><FormattedMessage id="contact.mainTitle" /></ContactTitle>

          <ContactInformation contactInfo={contactInfo} />

          </div>
        </Contact>

        <ContactContainer
        id="contact"
        

        >
          <FormContainer >
          <ContactTitle style={{
            marginBottom:'1.45rem',
          }}><FormattedMessage id='contact.formTitle' /></ContactTitle>
          <FormFooter showModal={showModal} setShowModal={setShowModal}/>
          <FormModal showModal={showModal} />
          </FormContainer>
          
        </ContactContainer>


        </StyledWrapper>
        <FooterBlock>
            <LogoFooterTag src={LogoFooter} />
          <StyledTitle> &copy; {footerContent.logo} 2021</StyledTitle>
         </FooterBlock>
    </>
  );
};

export default Footer;
