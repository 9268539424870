import React, { useEffect, useState, useMemo, useContext } from "react"
import styled from "styled-components"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import { globalHistory } from "@reach/router"
import PropTypes, { element } from "prop-types"
import useWindowSize from "utils/useWindowSize"

import Arrow from "components/Arrow/Arrow"

const languageName = {
  en: "ENG",
  fr: "FR",
  uk: "UKR",
  pl: "PL",
}

const StyledLanguageSelector = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-block;

  span {
    color: ${({ theme, ishome }) =>
      ishome ? theme.colors.headerItemWhite : theme.colors.headerItemBlack};
    font-size: 16px;
    margin-right: 8px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`

const StyledLanguageList = styled.ul`
  list-style: none;
  z-index:70;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  border: ${({ theme }) =>
    `0.5px solid ${theme.colors.contact.formFieldBorder}`};

  @media (max-width: 730px) {
    flex-direction: row;
    border: none;
  }

  @media (min-width: 730px) {
    margin: 0;
    position: absolute;
    left: -10px;
    top: 35px;
    width: auto;
    background: white;
    padding: 8px 16px;
  }
`

const StyledLanguageItem = styled.li`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.headerItemBlack};
  margin-bottom: 0;
  cursor: pointer;
  background: none;
  margin-right: 16px;
  border: none;

  @media (min-width: 730px) {
    margin-right: 0px;
  }

  @media (max-width: 730px) {
    flex-direction: row;
    border: none;
    font-size: 16px;
  }
`

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;

  span{
    text-transform:uppercase;
  }
`

const LanguageSwitcher = ({ ishome }) => {
  const location = globalHistory.location.pathname
  const size = useWindowSize()

  const [show, setShow] = useState(false);

  const [currentLand, setCurrentLand] = useState('')

  useEffect(() => {
    if (size.width < 730) {
      setShow(true)
      }


    setCurrentLand(localStorage.getItem('gatsby-intl-language'))


  }, [size.width])


  return (
    <StyledLanguageSelector ishome={ishome}>
      {size.width >= 730 && (
        <>
          <ToggleContainer onClick={() => setShow(!show)}>
            <span>{currentLand}</span>
            <Arrow show={show} />
          </ToggleContainer>
        </>
      )}

      <StyledLanguageList show={show}>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            languages.map(language => (
              <StyledLanguageItem
                key={language}
                
                onClick={() => {
                  localStorage.setItem('gatsby-intl-language', currentLocale);
                  changeLocale(language); }}
                style={{
                  fontWeight: currentLocale === language ? 700 : 400,
                }}
                location={location}
              >
                {languageName[language]}
              </StyledLanguageItem>
            ))
          }
        </IntlContextConsumer>
      </StyledLanguageList>
    </StyledLanguageSelector>
  )
}

LanguageSwitcher.propTypes = {
  black: PropTypes.bool,
}

export default LanguageSwitcher
