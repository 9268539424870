export const colors = {
  logoYellow: "hsl(54, 92%, 72%)",
  logoBlack: "hsl(0, 0%, 0%)",
  logoYellowHuge: "hsl(54, 92%, 72%)",
  headerItemWhite: "hsl(0, 0%, 99%)",
  headerItemBlack: "hsl(0, 0%, 0%)",
  headerLineSwitcherLight: "hsl(0, 0%, 75%)",
  headerLineSwitcherDark: "hsl(0, 0%, 10%)",
  footerBackground: "hsl(0, 4%, 95%)",
  footerText: "hsl(0, 0%, 54%)",
  footerTextActive: "hsl(0, 0$, 45%)",
  eclipseGrey: "hsl(0, 0%, 15%)",
  eclipseBlack: "hsl(0, 0%, 7%)",
  eclipseBlue: "hsl(209, 71%, 95%)",
  home: {
    homeBackground: "hsl(0, 0%, 12%)",
    infomationTextWhite: "hsl(0, 0%, 90%)",
    informationTitle: "hsl(0, 0%, 100%)",
    informationDescription: "hsl(0, 0%, 67%)",
    informationLine: "hsl(0, 1%, 85%)",
    buttonBlue: "hsl(194, 79%, 84%)",
    buttonPurple: "hsl(263, 72%, 76%)",
    squareYellow: "hsl(43, 76%, 82%)",
    squareRed: "hsl(360, 43%, 70%)",
  },
  menuMobile: {
    menuBackground: "hsl(54, 92%, 72%)",
    menuText: "hsl(0, 0%, 0%)",
  },
  services: {
    servicesBackground: "hsl(0, 4%, 95%)",
    textBlack: "hsl(0, 0%, 0%)",
    textWhite: "hsl(0, 0%, 92%)",
    textGrey: "hsl(0, 0%, 80%)",
    tableBackground: "hsl(0, 0%, 89%)",
    tableBlackPart: "hsl(0, 0%, 22%)",
    mobileBackground: "hsl(0, 0%, 12%)",
    buttonBackground: "hsl(54, 92%, 72%)",
    descriptionText: "hsl(0, 0%, 35%)",
    pictureBorder: "hsl(0, 0%, 85%)",
  },
  servicesOptions: {
    title: "hsl(0, 0%, 0%)",
    optionBackground: "#ECF9E9",
    optionBackgroundHover: "#C5F1D1",
    optionBorder: "#C5F1D1",
    optionText: "#333333",
  },
  contact: {
    contactBackground: "hsl(0, 4%, 95%)",
    contactTitle: "hsl(0, 0%, 0%)",
    formFieldBackground: "hsl(0, 0%, 100%)",
    formFieldText: "hsl(0, 0%, 48%)",
    formFieldBorder: "hsl(0, 0%, 85%)",
    formFieldBorderActive: "hsl(0, 0%, 0%)",
    formButtonBackground: "hsl(0, 0%, 82%)",
    formButtonText: "hsl(0, 0%, 40%)",
    contactInfoBackground: "hsl(0, 0%, 100%)",
    addButtonBackground: "hsl(0, 0%, 37%)",
    addButtonText: "hsl(0, 0%, 85%)",
  },
  notFound: {
    background: "hsl(0, 4%, 95%)",
    text: "hsl(0, 0%, 0%)",
  },
}
