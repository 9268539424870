import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

const StyledWrapper = styled.div`
  width: 13px;
  height: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 300;
  position: relative;

  transition: all 0.2s linear;
  div {
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.headerLineSwitcherDark};

    transition: all 0.2s linear;
    transform-origin: 0.7px;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }
    :last-child {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }

    ${({ light }) =>
      light &&
      css`
        background-color: ${({ theme }) =>
          theme.colors.headerLineSwitcherLight};
      `}

    ${({ open }) =>
      open &&
      css`
        background-color: ${({ theme }) => theme.colors.headerLineSwitcherDark};
      `}
  }
`

const MenuSwitcher = ({ light, open, ...props }) => {
  return (
    <>
      <StyledWrapper light={light} open={open} {...props}>
        <div />
        <div />
        <div />
      </StyledWrapper>
    </>
  )
}

MenuSwitcher.propTypes = {
  light: PropTypes.bool,
  open: PropTypes.bool.isRequired,
}

export default MenuSwitcher
