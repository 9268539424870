import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { FormattedMessage } from "gatsby-plugin-intl";

import doneImg from '../../../static/done.svg'


const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index:20;

  transform: ${({ showModal }) =>
    showModal ? "translateY(0)" : "translateY(-100vh)"};
  transition: all 1s cubic-bezier(0.21, 1.35, 0.57, 0.99);
`

const StyledParagraphContainer = styled.div`
  max-width: 500px;
  height: 300px;
  padding:30px 10px;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  background-color: white;
  border: 1px solid rgb(0, 173, 17);
`

const StyledParagraph = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: black;
  margin: 0;
  text-align: center;

  @media (max-width: 600px) {
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
`

const FormModal = ({ showModal }) => (
  <StyledWrapper showModal={showModal}>
    <StyledParagraphContainer>
      <img src={doneImg} />
      <StyledParagraph>
        <FormattedMessage id="contact.mailSuccess" />
      </StyledParagraph>
    </StyledParagraphContainer>
  </StyledWrapper>
)

FormModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
}
export default FormModal
